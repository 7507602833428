import React, {useEffect, useState} from "react";
import './TechnologyCard.scss';
import { GiSpeaker } from "react-icons/gi";
interface cardProps {
    data_uid: number,
    img_url: string,
    title: string,
    description: string,
    hidden: any
}

interface detailTypes {
    img_url: string,
    title: string,
    description: string,
    setShowDetail: any
}

const TechnologyDetail = (props: detailTypes) => {

    return(
        <div className={"detail"}>
            <div className={"detailImg"} onClick={()=>{
            window.location.href = "#PortfolioSection"}
            }>
                <img src={props.img_url} />
            </div>
            <div className={"detailText"}>
                <h1>{props.title}</h1>
                <p>
                    {props.description}
                </p>
            </div>
        </div>)
}

const TechnologyCard = (props: cardProps) => {
    const [showDetail, setShowDetail] = useState(false);
    const onDetail = () => {
        setShowDetail((prev) => !prev);
    }
    const onClose = () => {
        setShowDetail(false);
    }
    const [techHoverArea, setTechHoverArea] = useState("0");
    const renderDetailCard = () => {
        const detailItem = props.hidden.map((item: detailTypes, index: number) => {
            return <TechnologyDetail key={index} {...item} />
        })
        return detailItem;
    }
    return (
        <div>
            <div className={"TechnologyCard"}>
                <div className={"techImg"}>
                    <img src={props.img_url} />

                </div>
                <div className={"techText"}>
                    <div className={"techMain"}>
                        <h1 dangerouslySetInnerHTML={{__html: props.title}}></h1>
                        <p>
                            {props.description}
                        </p>
                    </div>
                    <button onClick={onDetail}>View more</button>
                </div>
            </div>
            { showDetail &&
                <div className={"TechnologyDetail"}>
                    <button onClick={onClose}>Close</button>
                    {renderDetailCard()}
                </div>
            }
        </div>
    )
}

export default TechnologyCard;