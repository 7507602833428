import './Portfolio.scss';
import React, {useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionTitle from '../../components/SectionTitle';
import PortfolioCard from "./PortfolioCard";
import {useRecoilState} from "recoil";
import {textDataRecoil, portfolioRecoil} from "../../common/data/DataManagementRecoil";
import { Button, Space, ConfigProvider } from 'antd';
import { IoChevronDownOutline } from "react-icons/io5";
const Portfolio: React.FC = ()=>{
    const [textData, setTextData] = useRecoilState(textDataRecoil);
    const [portfolioData, setPortfolioData] = useRecoilState(portfolioRecoil);
    const sectionText = textData.section;
    const props = {
        title: sectionText.sectionTitlePortfolio,
        subtitle: sectionText.sectionSubPortfolio
    }
    const [portfolioIndex, setPortfolioIndex] = useState(3);

    const portfolioDataLoad = ()=>{
        const result:any[] = [];
        const cardRender = (maximum: number)=>{
            for(let i=0; i<maximum; i++){
                const cardProps = {
                    project_name: portfolioData[i].project_name,
                    logo: portfolioData[i].logo,
                    created_date: portfolioData[i].created_date,
                    img_url: portfolioData[i].img_url,
                    img_alt: portfolioData[i].img_alt,
                    start_date: portfolioData[i].start_date,
                    end_date: portfolioData[i].end_date,
                    description: portfolioData[i].description,
                    stack: portfolioData[i].stack,
                    detail: portfolioData[i].detail,
                    link: portfolioData[i].link
                }
                result.push(<PortfolioCard key={i} {...cardProps}/>);
            }
        }
        if(portfolioData.length < portfolioIndex){
            cardRender(portfolioIndex-(portfolioIndex-portfolioData.length));
            return result;
        }
        cardRender(portfolioIndex);
        return result;
    }

    useEffect(()=>{
        AOS.init({

        });
    });

    return (
        <div className={"PortfolioSection"} id={"PortfolioSection"}>
            <SectionTitle {...props}/>
            {portfolioDataLoad()}
            <div onClick={()=>{
                setPortfolioIndex(portfolioIndex+3)}
            } style={{
                pointerEvents: portfolioData.length <= portfolioIndex ? "none":"auto"
            }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <ConfigProvider
                      theme={{
                          token: {
                              // colorPrimary: 'dodgerblue',
                          },
                      }}>
                        <Button type="primary" ghost block disabled={
                            portfolioData.length <= portfolioIndex ? true:false
                        }>
                            더보기 <IoChevronDownOutline size={20}/>
                        </Button>
                    </ConfigProvider>
                </Space>
            </div>
        </div>
    )
}
export default Portfolio;
