import "./KakaoMap.scss";
import React, {useCallback, useEffect, useState} from "react";
// @ts-ignore
// const {daum} = window;
const {kakao} = window;

const KakaoMap = ()=>{
  useEffect(()=> {
      // new daum.roughmap.Lander({
      //   "timestamp" : "1679553587228",
      //   "key" : "2e76f",
      //   "mapWidth" : "100%",
      //   "mapHeight" : "100%"
      // }).render();
    let container = document.getElementById('map');
    let options = {
      center: new kakao.maps.LatLng(37.541569885586576, 127.0534350776104),
      level: 4
    };

    let map = new kakao.maps.Map(container, options);
    map.setZoomable(false);

    // 마커가 표시될 위치입니다
    var markerPosition  = new kakao.maps.LatLng(37.541569885586576, 127.0534350776104);

// 마커를 생성합니다
    var marker = new kakao.maps.Marker({
      position: markerPosition
    });

// 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);
    var iwContent = '<div style="padding:5px; text-align: center; width: 180px"><a href="http://kko.to/sGZ5pTwtYO" target="_blank">서울숲 코오롱 디지털 타워 2차 309호 </a></div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
      iwPosition = new kakao.maps.LatLng(37.541569885586576, 127.0534350776104); //인포윈도우 표시 위치입니다

// 인포윈도우를 생성합니다
    var infowindow = new kakao.maps.InfoWindow({
      position : iwPosition,
      content : iwContent
    });

// 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
    infowindow.open(map, marker);

// 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    var mapTypeControl = new kakao.maps.MapTypeControl();

// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
  }, []);
  return (
        // <div
        //   style={{
        //     width: "100%",
        //     height: "100%"
        //   }}
        //   id="daumRoughmapContainer1679553587228"
        //   className="root_daum_roughmap root_daum_roughmap_landing"
        //
        // >
        // </div>
      <div id={"map"}>

      </div>
    );
}
export default KakaoMap;