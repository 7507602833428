import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SalesSlider.scss';
import i1 from '../../asset/SalesSlider/1.png';
import i2 from '../../asset/SalesSlider/2.png';
import i3 from '../../asset/SalesSlider/3.png';
import i4 from '../../asset/SalesSlider/4.png';
import i6 from '../../asset/SalesSlider/6.png';
import i7 from '../../asset/SalesSlider/7.png';
import i8 from '../../asset/SalesSlider/8.png';
import i9 from '../../asset/SalesSlider/9.png';
import i10 from '../../asset/SalesSlider/10.png';
import i11 from '../../asset/SalesSlider/11.png';
import i12 from '../../asset/SalesSlider/12.png';

const SalesSlider = ()=>{
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false, // 현재 컨텐츠 가운데 정렬
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover: true, // hover시 정지
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slideToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slideToScroll: 1,
                    centerMode: false

                }
            }
        ]
    };
    return (
            <div className={"saleSlideBox"}>
                <Slider {...settings}>
                    <div>
                        <img src={i1} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i2} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i3} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i4} style={{

                        }}/>
                    </div>

                    <div>
                        <img src={i6} style={{


                        }}/>
                    </div>
                    <div>
                        <img src={i7} style={{


                        }}/>
                    </div>
                    <div>
                        <img src={i8} style={{


                        }}/>
                    </div>
                    <div>
                        <img src={i9} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i10} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i11} style={{

                        }}/>
                    </div>
                    <div>
                        <img src={i12} style={{

                        }}/>
                    </div>
                </Slider>
            </div>
    );
}
export default SalesSlider;