import { atom } from "recoil";
export interface portfolioTypes{
    data_uid: number;
    project_name: string;
    logo: string;
    created_date: string;
    img_url: string[];
    img_alt: string[];
    start_date: string;
    end_date: string;
    description: string;
    stack: string[];
    detail: string;
    link: string;
}
export interface technologyTypes{
    data_uid: number;
    img_url: string;
    title: string;
    description: string;
    hidden: object[];
}
export interface textDataTypes{
    section: any,
    main: any,
    technology: any,
    portfolio: any,
    partners: any,
    certification: any,
    contact: any
}
export const portfolioRecoil = atom<portfolioTypes[]>({
    key: "portfolioData",
    default: [{
        "data_uid": 0,
        "project_name": "string",
        "logo": "string",
        "created_date": "err",
        "img_url": [],
        "img_alt": [],
        "start_date": "string",
        "end_date": "string",
        "description": "string",
        "stack": [],
        "detail": "string",
        "link": "string"
    }]
});
export const technologyRecoil = atom<technologyTypes[]>({
    key: "technologyData",
    default: [{
        "data_uid": 0,
        "img_url": "",
        "title": "",
        "description": "",
        "hidden": []
    }]
})
export const textDataRecoil = atom<textDataTypes>({
    key: "textData",
    default: {
        "section":1,
        "main":1,
        "technology":1,
        "portfolio":1,
        "partners":1,
        "certification":1,
        "contact":1
    }
});
export const testRecoil = atom<number>({
    key: "recoilTest",
    default: 0
});
