import './PortfolioCard.scss';
import React, {useEffect, useState} from 'react';
import { Carousel} from 'antd';
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import {useRecoilState} from "recoil";
import {textDataRecoil} from "../../../common/data/DataManagementRecoil";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '250px',
  color: '#fff',
  lineHeight: '250px',
  textAlign: 'center',
  background: '#364d79',
};

interface cardProps {
  project_name: string;
  logo: string;
  created_date: string;
  img_url: string[];
  img_alt: string[];
  start_date: string;
  end_date: string;
  description: string;
  stack: string[];
  detail: string;
  link: string;
}

const PortfolioCard = (props : cardProps)=>{
  const [textData, setTextData] = useRecoilState(textDataRecoil);
  const [detail, setDetail] = useState("none");
  const showDetail = ()=>{
    setDetail("block");
  }
  const onClose = ()=>{
    setDetail("none");
  }
  const moveLink = (link: string)=>{
    window.open(link, "_blank")
  }
  return (
    <div className={"PortfolioCard"}>
      <div>
        <Carousel arrows={false}>
          {
            props.img_url.map((v,i)=>{
              return <div key={i}><img
                src={props.img_url[i]}
                alt={props.img_alt[i]}
                title={props.img_alt[i]}
              /></div>
            })
          }
        </Carousel>
      </div>
      {
        (detail == "block") &&
          <div className={"portfolioDetailArea"} style={{
            display: detail
          }}>
              {props.detail}
              <h3 onClick={onClose}>close</h3>
          </div>
      }
      <div>
        <h1>{props.project_name}</h1>
        <h5>{`${textData.portfolio.projectPeriodText} : ${props.start_date} ~ ${props.end_date}`}</h5>
        <p>{props.description}{props.link && (<a onClick={()=>{moveLink(props.link)}}>- {props.link}</a>)}</p>
        <h5>{textData.portfolio.projectSkillText} :</h5>
        <div>
          {
            props.stack.map((v, i)=>{
              return <span key={i} style={{
                width: "30px",
                height: "30px",
                backgroundImage: `url(${props.stack[i]})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                marginRight: "10px"
              }}></span>
            })
          }
        </div>
        <img src={props.logo} />
        <button onClick={showDetail}>Show Detail</button>
      </div>
    </div>
  );
}
export default PortfolioCard;