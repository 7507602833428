import './Contact.scss';
import React, {useCallback, useEffect, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {useRecoilState} from "recoil";
import {textDataRecoil} from "../../common/data/DataManagementRecoil";
import SectionTitle from "../../components/SectionTitle";
import KakaoMap from "../../components/KakaoMap";
import {FaMapMarkerAlt, FaEnvelopeSquare, FaPhoneAlt, FaGoogle} from "react-icons/fa";
// @ts-ignore
const {kakao} = window;

const Contact = ()=>{

    const [textData, setTextData] = useRecoilState(textDataRecoil);
    const sectionText = textData.section;
    const props = {
        title: sectionText.sectionTitleContact,
        subtitle: sectionText.sectionSubContact
    }
    // const partnersText = textData.partners;
    return (
      <div className={"ContactSection"} id={"ContactSection"}>
          <SectionTitle {...props}/>
          {/*<KakaoMap></KakaoMap>*/}
          <div className={"kakaomapBox"}>
              <div>
                  <div>
                      <FaMapMarkerAlt className={"icon"} />
                      <div>
                          <h1>Location :</h1>
                          <p onClick={()=>{window.open("http://kko.to/w0UpWVN-To", "_blank")}}>서울시 성동구 성수이로7길 27, 서울숲코오롱디지털타워2차 309호</p>
                      </div>
                  </div>
                  <div>
                      <FaEnvelopeSquare className={"icon"}/>
                      <div>
                          <h1>Email :</h1>
                          <p><a href="mailto:help@4stech.co.kr?subject=help.">help@4stech.co.kr</a></p>

                      </div>
                  </div>
                  <div>
                      <FaPhoneAlt className={"icon"}/>
                      <div>
                          <h1>Call :</h1>
                          <p>02-6013-6300</p>
                      </div>
                  </div>
              </div>
              <div style={{
                  height: "700px"
              }}>
                  {(kakao !== undefined)&&<KakaoMap></KakaoMap>||<img className={"kakaomap_err"} onClick={()=>{window.open("http://kko.to/w0UpWVN-To", "_blank")}} />}
              </div>
          </div>
      </div>
    )
}
export default Contact;
