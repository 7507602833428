import "./Footer.scss";
import { Cascader } from 'antd';


const Footer = ()=>{
    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }
    const options: Option[] = [
        {
            value: "포에스텍",
            label: '포에스텍',

        },
        {
            value: "쏘드라이브",
            label: '쏘드라이브',

        }
    ];
    const onChange: any = (value: string[] | number[]) => {
        switch (value[0]){
            case "포에스텍":
                window.location.href = "/";
                break;
            case "쏘드라이브":
                window.open("http://www.sodrive.co.kr/", "_blank")
                break;
            default:
                window.location.href = "/";
                break;
        }
    };
    return (
        <div className={"FooterArea"}>
            <div>
                <img></img>
                <p>
                    (주)포에스텍<br></br>
                    대표 이사 : 김용구 | 사업자등록번호 215-87-44300<br></br>
                    COPYRIGHT ⓒ 4STECH. ALL RIGHTS RESERVED<br></br>
                </p>
                <div>
                    <Cascader className={"cascader"} size={"middle"} options={options} placeholder="사이트맵" onChange={onChange}/>
                </div>
            </div>
        </div>
    );
}
export default Footer;